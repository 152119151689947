'use strict';

import $ from 'jquery';

import 'bootstrap';
import 'bootstrap-fileinput';
import 'bootstrap-fileinput/themes/fa/theme';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver'
import './modules/fetch';
import './modules/modal';
import './modules/form.file.loading';
import 'simple-scrollbar';
import 'fetch-polyfill';
import 'custom-event-polyfill';
import 'nodelist-foreach-polyfill';
import select2 from 'select2';

$.noConflict(true);

select2($);

document.querySelectorAll('select.select2, select[multiple]:not(.raw)').forEach(function(select){
    function select2TemplateSelection(state) {
        var item = document.createElement('div');
        item.style.display = 'inline';
        item.innerHTML = `
                        <span>${state.text}</span> 
                        <a data-href="${state.element.dataset.editUrl}" data-toggle="modal" data-target="${select.dataset.edit}" class="btn btn-info btn-transparent btn-xs">
                            <span class="icon-pencil"></span>
                        </a>
                    `;

        handleModals(item);

        item.addEventListener('modal.submitted', function(event){
            select.querySelector('option[value="' + event.detail.id + '"]').text = event.detail.name;
            $(select).select2('destroy');

            $(select).select2({
                templateSelection: select2TemplateSelection
            });
        }, true);

        return item;
    }

    if (typeof select.dataset.edit === 'string') {
        $(select).select2({
            templateSelection: select2TemplateSelection
        });
    } else {
        $(select).select2();
    }
});

/*
 * Click on a element, it clicks on target
 * Allow to place a select wherever you want
 */
(function () {
    let triggers = document.querySelectorAll('[data-relay-target-id]');

    setTimeout(function () {
        triggers.forEach(trigger => {
            if (trigger.dataset.copyLabel === 'true') {
                trigger.innerText = target.innerText
            }

            trigger.addEventListener('click', function () {
                document.getElementById(trigger.dataset.relayTargetId).click()
            })
        });
    }, 1); // Differ
})();

/*
 * Click on a element, it remove target
 */
(function () {
    let triggers = document.querySelectorAll('[data-remove-target-id]');

    triggers.forEach(trigger => {
        let target = document.getElementById(trigger.dataset.removeTargetId);

        if (target !== undefined) {
            trigger.addEventListener('click', function() {
                target.remove();
            });
        }
    });
})();

/*
 * Select change trigger redirect
 */
(function () {
    let selects = document.querySelectorAll('[data-select-href]');

    selects.forEach(select => {
        select.addEventListener('change', function() {
            location.href = select.value;
        });
    });
})();

/*
 * Toggle class
 */
(function () {
    let btn = document.querySelectorAll('[data-toggle-class][data-target-id]');

    btn.forEach(select => {
        select.addEventListener('click', function() {
            let toggleClass = select.dataset.toggleClass;
            let targetId = select.dataset.targetId;
            let targetElement = document.getElementById(targetId);

            if (targetElement !== undefined) {
                targetElement.classList.toggle(toggleClass);
            }
        });
    });
})();

$(function () {
    handleSimpleDropzone()
})

function handleSimpleDropzone() {
    document.querySelectorAll('.simple-dropzone').forEach(fileInput => {
        let dropzoneContainer = document.createElement('div')

        dropzoneContainer.className = 'dropzone-container'
        fileInput.parentNode.insertBefore(dropzoneContainer, fileInput)

        // Drop files
        dropzoneContainer.ondrop = function(event) {
            event.preventDefault()
            let files = event.dataTransfer.files

            fileInput.files = files
            fileInput.dispatchEvent(new Event('change'))
            fileInput.form.dispatchEvent(new Event('change'))
            zoneOut(event)
        }

        dropzoneContainer.onclick = function() {
            fileInput.click();
        }

        dropzoneContainer.ondragover = zoneIn
        dropzoneContainer.ondragleave = zoneOut

        function zoneIn(event) {
            event.preventDefault()
            dropzoneContainer.classList.add('ondragover')
        }

        function zoneOut(event) {
            event.preventDefault()
            dropzoneContainer.classList.remove('ondragover')
        }
    })
}

window.toolkitFolderZip = function (element, zipName, data) {
    const cardBody = element.parentNode

    function urlToPromise(url) {
        return new Promise(function (resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (error, data) {
                if (error) {
                    reject(error)
                } else {
                    resolve(data)
                }
            })
        })
    }

    cardBody.classList.add('loading')

    const files = JSON.parse(data)
    let zip = new JSZip()

    for (const i in Object.keys(files)) {
        const file = files[i]
        zip.file(`${file.folderPath} / ${file.name}.${file.extension}`, urlToPromise(file.path), { binary: true })
    }

    function onUpdate(metadata) {
        cardBody.dataset.text = `Zipping... (${metadata.percent.toFixed(2)}%)`
    }

    delete cardBody.dataset.text

    zip.generateAsync({ type: 'blob' }, onUpdate)
        .then(function callback(blob) {
            saveAs(blob, `${zipName}.zip`)
            cardBody.classList.remove('loading')
            delete cardBody.dataset.text
        }).catch(function (error) {
            console.error(error)
            cardBody.dataset.text = 'Error'
        })
}
